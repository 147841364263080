import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  pendingEvents: [],
  upcomingEvents: [],
  loading: false,
  error: null,
};

export const fetchGlobalEvents = createAsyncThunk(
  "global/fetchEvents",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}calendly/events/`, {
        email,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Global events
    builder
      .addCase(fetchGlobalEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGlobalEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingEvents = action.payload?.pending || [];
        state.upcomingEvents = action.payload?.upcoming || [];
      })
      .addCase(fetchGlobalEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

export default globalSlice.reducer;
