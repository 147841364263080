import React, { useEffect, useState } from "react";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import Sidebar from "layouts/sidebar/sidebar";
import Cx from "./page.module.scss";
import { Link } from "react-router-dom";
const ReportTable = () => {
  const [data, setData] = useState(null);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.agfinancehub.com/api/XeroList/?user_id=${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <>
          <div className={Cx.contentpopint}>
            <ul>
              {data.map((item, index) => (
                <li key={index}>{renderData(item)}</li>
              ))}
            </ul>
          </div>
        </>
      );
    } else if (typeof data === "object" && data !== null) {
      return (
        <>
          <div className={Cx.contentpopint}>
            <ul>
              {Object.entries(data).map(([key, value], index) => (
                <li key={index}>
                  <strong>{key}:</strong>
                  {renderData(value)}
                </li>
              ))}
            </ul>
          </div>
        </>
      );
    } else {
      return <span>{String(data)}</span>;
    }
  };

  if (!data) {
    return (
      <>
        <div className={Cx.lodertext}>Xero Data Not Found</div>
      </>
    );
  }

  const balanceSheetReports = data[0]?.data?.balance_sheet?.Reports || [];
  const profitAndLossReports = data[0]?.data?.profit_and_loss?.Reports || [];

  return (
    <>
      <Header activeClass="Report Table" />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar />
            </div>

            <div className="col-lg-9">
              <div className={Cx.tableheading}>
                <Link
                  className={Cx.btnsyncdata}
                  to={
                    "https://login.xero.com/identity/connect/authorize?response_type=code&client_id=AF64D25746694AFF9B97EC735E4C6A1E&redirect_uri=https%3A//web.agfinancehub.com/&scope=openid%20profile%20email%20offline_access%20accounting.transactions%20accounting.reports.read%20accounting.settings"
                  }
                  target="_blank"
                >
                  Resync Data
                </Link>
              </div>
              <div className={Cx.reporttablesection}>
                <div className="conatiner">
                  <div className="row mx-0 px-0">
                    <div className="col-12 mx-auto">
                      <div className={Cx.main_dataarea}>
                        <h2>Balance Sheet Reports</h2>
                        {balanceSheetReports.length > 0 ? (
                          balanceSheetReports.map((report, index) => (
                            <div key={index}>
                              <h3>Report {index + 1}</h3>
                              {renderData(report)}
                            </div>
                          ))
                        ) : (
                          <p>No Data Found. Please Sync Your Xero Account </p>
                        )}

                        <div className="mt-5">
                          <h2>Profit and Loss Reports</h2>
                          {profitAndLossReports.length > 0 ? (
                            profitAndLossReports.map((report, index) => (
                              <div key={index}>
                                <h3>Report {index + 1}</h3>
                                {renderData(report)}
                              </div>
                            ))
                          ) : (
                            <p>No Data Found. Please Sync Your Xero Account </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
    </>
  );
};

export default ReportTable;
