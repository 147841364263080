import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import { useNavigate } from "react-router-dom";
import { FiMic, FiMicOff, FiTrash } from "react-icons/fi";
import { PiSpeakerHighDuotone, PiSpeakerSlashDuotone } from "react-icons/pi";
import Header from "layouts/hearder/Header";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";
import { showSuccessToast } from "utils/Toster";
import Sidebar from "layouts/sidebar/sidebar";

const DiscoveryAnalysisAdd = () => {
  const [activeMicIndex, setActiveMicIndex] = useState(null);
  const [activeSpeakIndex, setActiveSpeakIndex] = useState(null);
  const [discoveryCallQuestionnaire, setDiscoveryCallQuestionnaire] = useState([]);
  const [recorders, setRecorders] = useState({});
  const [audioFiles, setAudioFiles] = useState({});
  const [audioPlayers, setAudioPlayers] = useState({});
  const [recordingStatus, setRecordingStatus] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}question/?user_type=taxrelief&category=Discovery%20Call%20Questionnaire`
      );

      setDiscoveryCallQuestionnaire(response?.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${userId}&category=Discovery%20Call%20Questionnaire`
      );
      const answers = response?.data;
      setTextAnswers(
        answers.reduce((acc, ans) => {
          acc[ans?.question_id] = ans?.answer;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
    fetchAnswers();
  }, []);

  const handleMicToggle = (index) => {
    if (activeMicIndex !== null && activeMicIndex !== index) {
      stopRecording(activeMicIndex);
    }

    if (activeMicIndex === index) {
      stopRecording(index);
      setActiveMicIndex(null);
    } else {
      startRecording(index);
      setActiveMicIndex(index);
      setActiveSpeakIndex(null);
    }
  };

  const handleSpeakToggle = (index) => {
    if (activeSpeakIndex !== null && activeSpeakIndex !== index) {
      stopSpeaking(activeSpeakIndex);
    }

    if (activeSpeakIndex === index) {
      stopSpeaking(index);
      setActiveSpeakIndex(null);
    } else if (audioFiles[index]) {
      startSpeaking(index);
      setActiveSpeakIndex(index);
      setActiveMicIndex(null);
    }
  };

  const startRecording = (index) => {
    const recorder = new MicRecorder({ bitRate: 128 });
    recorder
      .start()
      .then(() => {
        setRecorders((prevRecorders) => ({
          ...prevRecorders,
          [index]: recorder,
        }));
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = (index) => {
    recorders[index]
      ?.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, `question${index}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setAudioFiles((prevFiles) => ({
          ...prevFiles,
          [index]: file,
        }));
        setRecordingStatus((prev) => ({
          ...prev,
          [index]: "Recording complete!",
        }));
      })
      .catch((e) => console.error(e));
  };

  const startSpeaking = (index) => {
    const player = new Audio(URL.createObjectURL(audioFiles[index]));
    player.play();
    setAudioPlayers((prev) => ({ ...prev, [index]: player }));
    player.onended = () => {
      setActiveSpeakIndex(null);
      setAudioPlayers((prev) => {
        const newState = { ...prev };
        delete newState[index];
        return newState;
      });
    };
  };

  const stopSpeaking = (index) => {
    const player = audioPlayers[index];
    if (player) {
      player.pause();
      player.currentTime = 0;
    }
  };

  const deleteRecording = (index) => {
    setAudioFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[index];
      return newFiles;
    });
    setRecordingStatus((prev) => {
      const newStatus = { ...prev };
      delete newStatus[index];
      return newStatus;
    });
  };

  const handleTextChange = (index, event) => {
    setTextAnswers((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const uploadAudioFile = async (audioFile) => {
    const formData = new FormData();
    formData.append("audio_file", audioFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}audio/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    try {
      const answerData = await Promise.all(
        discoveryCallQuestionnaire.map(async (item, index) => {
          let audioLink = null;

          if (audioFiles[index]) {
            const s3Link = await uploadAudioFile(audioFiles[index]);
            audioLink = s3Link?.audio_file;
          }
          return {
            user: localStorage.getItem("userId"),
            question: item?.id,
            answer: textAnswers[index] ? [textAnswers[index]] : audioLink ? [audioLink] : [],
          };
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}bulkanswer/`,
        answerData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response) {
        navigate("/upload-document");
        showSuccessToast(response.message);
        fetchAnswers();
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3" style={{ paddingLeft: "0px", top: "-3px" }}>
              <Sidebar />
            </div>
            <div className="col-lg-9">
              <div className={Cx.mainheadingUploaddocument}>
                <h1>Discovery Analysis</h1>
                <form className={Cx.technicalanalysisForm}>
                  {discoveryCallQuestionnaire &&
                    discoveryCallQuestionnaire.map((item, index) => (
                      <div className={Cx.formGroup} key={index}>
                        <label htmlFor={`income${item}`}>
                          <span>
                            Q.{item?.order}.{item?.partno === 1 ? "" : item?.partno}
                          </span>{" "}
                          {item?.question}
                        </label>
                        <div className={Cx.inputGroup}>
                          <textarea
                            id={`income${item}`}
                            placeholder={item?.question}
                            onChange={(e) => handleTextChange(index, e)}
                            required
                          />
                          <div className={Cx.toggleIcon}>
                            <div onClick={() => handleMicToggle(index)}>
                              {activeMicIndex === index ? <FiMic /> : <FiMicOff />}
                            </div>
                            {audioFiles[index] && (
                              <div onClick={() => handleSpeakToggle(index)}>
                                {activeSpeakIndex === index ? (
                                  <PiSpeakerSlashDuotone />
                                ) : (
                                  <PiSpeakerHighDuotone />
                                )}
                              </div>
                            )}
                            {audioFiles[index] && (
                              <div onClick={() => deleteRecording(index)}>
                                <FiTrash />
                              </div>
                            )}
                          </div>
                        </div>
                        {recordingStatus[index] && <p>{recordingStatus[index]}</p>}
                      </div>
                    ))}
                </form>
              </div>

              <div className={Cx.mainheading}>
                <div className="mb-5">
                  <button onClick={handleSubmit} className={Cx.submitButton2}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DefaultFooter content={footerRoutes} />
        </div>
      </section>
      {/*   <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
};

export default DiscoveryAnalysisAdd;
