import React from "react";
import { Link } from "react-router-dom";
import Cx from "./page.module.scss";

const QuestionInstructionScreen = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-11 m-auto">
            <div className={Cx.instructionspage}>
              <div className={Cx.instructionstop}>
                {/*   <Link to="/dashboard" className={Cx.backbtn}>
                  <IoArrowBackOutline />
                </Link> */}
                <div className={Cx.introText}>
                  <h2>Instructions</h2>
                </div>
              </div>
              <div className={`${Cx.content} mb-5`}>
                <ul className={Cx.list}>
                  <li>
                    1.<b>Project Focu</b>s: We are looking for projects you worked on during the
                    year where you aimed to advance science or technology, specifically by
                    addressing and attempting to overcome scientific or technological uncertainties.
                    By &quot;uncertainty,&quot; we mean challenges that are scientifically or
                    technologically complex and not easily resolved by professionals in the field.
                  </li>
                  <li>
                    2.<b>Structure</b>: For each project, we will explore the objectives and
                    background, including how the project originated. We will then identify the
                    uncertainties faced and discuss the solutions, including the steps you took to
                    overcome these challenges and the difficulties you encountered along the way.
                    Typically, each project will have three uncertainties and three solutions.
                    However, if there are fewer or no solutions, that’s fine. The key focus is the
                    effort made to overcome the challenges. Once we have completed this for one
                    project, we will move on to the next.
                  </li>
                  <li>
                    3.<b>Direct R&D Definition</b>: Activities that directly contribute to resolving
                    scientific or technological uncertainty are classified as R&D.
                  </li>
                  <li>
                    4.<b>Indirect R&D Activities</b>: Some qualifying indirect activities related to
                    the project may also be considered R&D. However, activities that do not directly
                    contribute to resolving the scientific or technological uncertainties of the
                    project are not considered R&D.
                  </li>
                  <li>
                    When completing the financial exercise, we will provide a clear definition of
                    direct and indirect qualifying activities, allowing you to accurately allocate
                    the staff and subcontractors involved in the project.
                  </li>
                </ul>
              </div>
              <div className="text-center mb-5">
                <Link className={`${Cx.nextbtn} `} to={"/technical-analysis"}>
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionInstructionScreen;
