import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ isAuthenticated, children }) => {
  const UserType = localStorage.getItem("UserType");

  return isAuthenticated ? (
    <Outlet>{children}</Outlet>
  ) : (
    <Navigate to={UserType === "is_business" ? "/dashboard-business" : "/dashboard"} />
  );
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
