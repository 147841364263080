// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCfzDUP42N01ZjxDr2dbHQagVNGjP5bvkQ",
  authDomain: "agfinancehub-b57ef.firebaseapp.com",
  projectId: "agfinancehub-b57ef",
  storageBucket: "agfinancehub-b57ef.firebasestorage.app",
  messagingSenderId: "110320147728",
  appId: "1:110320147728:web:7fd1fc8248186f431d1995",
  measurementId: "G-PK93VM4402",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
// const provider = new GoogleAuthProvider();
export { auth };
