// import Dashboard from "pages/LandingPages/dashboard/Dashboard";
// import DashboardBusiness from "pages/LandingPages/dashboardbusiness/DashboardBusiness";
// import LookingFor from "pages/LandingPages/lookingfor/LookingFor";
// import SignInOld from "pages/LandingPages/signinold/SignIn";
// import About from "pages/LandingPages/About";
// import PrivacyPolicy from "pages/LandingPages/PrivacyPolicy";
// import TermsCondition from "pages/LandingPages/TermsConditions";
// import ContactUs from "layouts/pages/landing-pages/contact-us";

// const getRoutes = () => {
//   const token = localStorage.getItem("userId");
//   const UserTypes = localStorage.getItem("UserType");

//   if (token) {
//     return [
//       {
//         name: "Dashboard",
//         route: UserTypes === "is_business" ? "/dashboard-business" : "/dashboard",
//         component: UserTypes === "is_business" ? <DashboardBusiness /> : <Dashboard />,
//       },
//       {
//         name: "My Finance",
//         route: UserTypes === "is_business" ? "/dashboard-business" : "/lookingfor",
//         component: UserTypes === "is_business" ? <DashboardBusiness /> : <LookingFor />,
//       },
//       {
//         name: "My Tax",
//         route: UserTypes === "is_taxrelief" ? "/dashboard" : "/lookingfor",
//         component: UserTypes === "is_taxrelief" ? <Dashboard /> : <LookingFor />,
//       },

//       {
//         name: "About",
//         route: "/about",
//         component: <About />,
//         collapse: [
//           {
//             name: "contact support",
//             route: "/contact-support",
//             component: <ContactUs />,
//           },
//           {
//             name: "terms & conditions",
//             route: "/terms&condition",
//             component: <TermsCondition />,
//           },
//           {
//             name: "privacy & policy",
//             route: "/privacy&policy",
//             component: <PrivacyPolicy />,
//           },
//         ],
//       },
//     ];
//   } else {
//     return [
//       {
//         name: "My Finance",
//         route: "/login",
//         component: <SignInOld />,
//       },
//       {
//         name: "My Tax",
//         route: "/login",
//         component: <SignInOld />,
//       },
//       {
//         name: "About",
//         route: "/about",
//         component: <About />,
//         collapse: [
//           {
//             name: "contact support",
//             route: "/contact-support",
//             component: <ContactUs />,
//           },
//           {
//             name: "terms & conditions",
//             route: "/terms&condition",
//             component: <TermsCondition />,
//           },
//           {
//             name: "privacy & policy",
//             route: "/privacy&policy",
//             component: <PrivacyPolicy />,
//           },
//         ],
//       },
//       {
//         name: "Login",
//         route: "/login",
//         component: <SignInOld />,
//       },
//     ];
//   }
// };

// const routes = getRoutes();

// export default routes;

import Dashboard from "pages/LandingPages/dashboard/Dashboard";
import DashboardBusiness from "pages/LandingPages/dashboardbusiness/DashboardBusiness";
import LookingFor from "pages/LandingPages/lookingfor/LookingFor";
import SignupLogin from "pages/LandingPages/Signuplogin/Signuplogin";
import About from "pages/LandingPages/About";
import PrivacyPolicy from "pages/LandingPages/PrivacyPolicy";
import TermsCondition from "pages/LandingPages/TermsConditions";
import ContactUs from "layouts/pages/landing-pages/contact-us";
//import Home from "pages/home";

const getRoutes = () => {
  const token = localStorage.getItem("userId");
  const UserTypes = localStorage.getItem("UserType");

  if (token) {
    return [
      UserTypes && {
        name: "Dashboard",
        route: UserTypes === "is_business" ? "/dashboard-business" : "/dashboard",
        component: UserTypes === "is_business" ? <DashboardBusiness /> : <Dashboard />,
      },
      {
        name: "Book a free Discovery Call",
      },
      {
        name: "My Finance",
        route: UserTypes === "is_business" ? "/dashboard-business" : "/lookingfor",
        component: UserTypes === "is_business" ? <DashboardBusiness /> : <LookingFor />,
      },
      {
        name: "My Tax",
        route: UserTypes === "is_taxrelief" ? "/dashboard" : "/lookingfor",
        component: UserTypes === "is_taxrelief" ? <Dashboard /> : <LookingFor />,
      },
      {
        name: "About",
        route: "/about",
        component: <About />,
        collapse: [
          {
            name: "contact support",
            route: "/contact-support",
            component: <ContactUs />,
          },
          {
            name: "terms & conditions",
            route: "/terms&condition",
            component: <TermsCondition />,
          },
          {
            name: "privacy & policy",
            route: "/privacy&policy",
            component: <PrivacyPolicy />,
          },
        ],
      },
    ].filter(Boolean);
  } else {
    return [
      {
        name: "Book a free Discovery Call",
        route: "/login",
        component: <SignupLogin />,
      },
      {
        name: "My Finance",
        route: "/login",
        component: <SignupLogin />,
      },
      {
        name: "My Tax",
        route: "/login",
        component: <SignupLogin />,
      },
      {
        name: "About",
        route: "/about",
        component: <About />,
        collapse: [
          {
            name: "contact support",
            route: "/contact-support",
            component: <ContactUs />,
          },
          {
            name: "terms & conditions",
            route: "/terms&condition",
            component: <TermsCondition />,
          },
          {
            name: "privacy & policy",
            route: "/privacy&policy",
            component: <PrivacyPolicy />,
          },
        ],
      },
      {
        name: "Login",
        route: "/login",
        component: <SignupLogin />,
      },
    ];
  }
};

const routes = getRoutes();

export default routes;
