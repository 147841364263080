import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import calendlyReducer from "./slice/getSlice";
import globalReducer from "./slice/globalSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    calendly: calendlyReducer,
    global: globalReducer,
  },
});

export default store;
