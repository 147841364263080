import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
/* import Presentation from "../../../assets/images/sliderOne.jpg";
import Presentation2 from "../../../assets/images/sliderTwo.jpg";
import Presentation3 from "../../../assets/images/sliderThree.jpg";
import Logo from "../../../assets/images/splashLogo.png";

import OwlCarousel from "react-owl-carousel2";*/

/* ---owl-- */
import $ from "jquery";
window.$ = window.jQuery = $;

import "react-owl-carousel2/lib/styles.css";

import Headerhome from "layouts/hearderhome/Headerhome";
// AG Finance React examples
/* import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes"; */
function ContactUs() {
  /*   const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    dotsClass: Cx.customDots,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: [false],
    responsive: {
      0: { items: 1 },
      768: { items: 1 },
      1024: { items: 1 },
    },
  }; */
  return (
    <>
      <Headerhome />
      <MKBox position="fixed" top="0.5rem" width="100%">
        {/*    <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/agfinance-hub-react",
            label: "free download",
            color: "info",
          }}
        /> */}
      </MKBox>
      <div className={Cx.sectioncontact}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={`${Cx.section_padding} ${Cx.containerarea}`}
        >
          <Grid item lg={6} md={12}>
            <div className={Cx.imagesidesignup}>
              <div className={Cx.imgarea}>
                {/* <OwlCarousel options={options}>
                  <div className={Cx.imagepoint}>
                    <img
                      src={Presentation}
                      alt="company_img"
                      className="lazy-img img-fluid"
                      loading="lazy"
                    />
                    {
                      <div className={Cx.logoimge}>
                        <img
                          src={Logo}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                      </div>
                    }
                    <div className={Cx.textareasignup}>
                      <h2>Welcome to AG Finance</h2>
                      <p>
                        The world s first tech-led finance and tax solutions company, providing
                        businesses access to the largest network of lenders in the UK, as well as
                        the UK s best tax recovery services.
                      </p>
                    </div>
                  </div>
                  <div className={Cx.imagepoint}>
                    <img
                      src={Presentation2}
                      alt="company_img"
                      className="lazy-img img-fluid"
                      loading="lazy"
                    />
                    {
                      <div className={Cx.logoimge}>
                        <img
                          src={Logo}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                      </div>
                    }
                    <div className={Cx.textareasignup}>
                      <h2>Unlock the Power of Business Finance</h2>
                      <p>
                        Bespoke finance solutions for your business from £10,000 to £20,000,000. Our
                        network has delivered just under £1bn to UK businesses.
                      </p>
                    </div>
                  </div>
                  <div className={Cx.imagepoint}>
                    <img
                      src={Presentation3}
                      alt="company_img"
                      className="lazy-img img-fluid"
                      loading="lazy"
                    />
                    {
                      <div className={Cx.logoimge}>
                        <img
                          src={Logo}
                          alt="company_img"
                          className="lazy-img img-fluid"
                          loading="lazy"
                        />
                      </div>
                    }
                    <div className={Cx.textareasignup}>
                      <h2>Explore Corporate Tax Relief</h2>
                      <p>
                        Identify and utilise tax relief opportunities for your business with our
                        award-winning Tax Recovery Services team.
                      </p>
                    </div>
                  </div>
                </OwlCarousel> */}
                <div className={Cx.contacttext}>
                  <h2>Contact Us</h2>
                  <p>
                    Please use this contact form and let us know if you have any questions about
                    your order, subscription or something else.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={12}>
            <div className={Cx.contactforms}>
              <MKBox
                variant="gradient"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={4}
                mt={-3}
                className={Cx.contactheading}
              >
                <MKTypography className={Cx.heading_textarea}>
                  <h2>Contact us</h2>
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further questions, including partnership opportunities, please email
                  hello@creative-tim.com or contact using our contact form.
                </MKTypography>
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="tel"
                        variant="standard"
                        label="Phone"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Subject"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Description"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <div className={Cx.mainheading}>
                      <button type="submit" className={Cx.submitButton_contact}>
                        Send Message
                      </button>
                    </div>
                  </Grid>
                </MKBox>
              </MKBox>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ bgColor: "#ffff", padding: "0px 0px", marginTop: "0px" }}>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default ContactUs;
