import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Cx from "../../../style/custom.module.scss";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Headerhome from "layouts/hearderhome/Headerhome";

/* import bgImage from "assets/images/bg-about-us.jpg";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MKButton from "components/MKButton"; */

const Blogs = () => {
  return (
    <>
      {/* <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://www.creative-tim.com/product/agfinance-hub-react",
        label: "free download",
        color: "default",
      }}
    /> */}
      <Headerhome />

      <MKBox
        width="100%"
        className="pt-5 pb-5"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={Cx.contantabout}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <div className={Cx.main_heading}>
              <MKTypography>
                <h1>Research & Development April 2024</h1>
              </MKTypography>
              <MKTypography>
                <h2>AG FINANCE AND TECHNOLOGY GROUP LTD</h2>
              </MKTypography>
            </div>
          </Grid>
        </Container>
      </MKBox>
      <Card className="p-4 mx-5">
        <div className={Cx.abutotextarea}>
          <MKTypography variant="body1" className={Cx.heading_textarea}>
            <p>
              In the 2023 Autumn Statement, adjustments were outlined to simplify and enhance the
              R&D tax incentive program in the UK. The following amendments came into force from
              April 1, 2024. At the bottom we have included the most notable changes from the 2024
              Autumn Statement, namely to the Updates on R&D Intensive Scheme (RDIS).
            </p>
            <p>
              Its network has provided just under £1bn in funding to UK businesses and has secured
              substantial corporate tax relief for eligible UK companies.The AG Finance & Technology
              Group is pioneering how UK businesses unlock tax relief and business finance. Your
              needs, their expertise, one destination — AG Finance Hub.&rsquo;
              <span>
                — <b>The Independent The term</b>
              </span>
            </p>
          </MKTypography>
          <MKTypography variant="body1" className={Cx.heading_textarea}>
            <h2>Mission:</h2>
            <p>
              Our mission is to pioneer the way UK businesses unlock tax relief and access business
              finance. We are developing proprietary AI technology designed to automate, de-risk,
              and simplify corporate tax and financing processes in the UK.
            </p>
            <h2> Services:</h2>
            <p>
              No matter where you are on your business journey, whether you’re just starting out or
              managing a multimillion-pound empire, we’re here to help. Whether you need access to
              unsecured financing through a special terms lender for UK tenants or homeowners,
              secured business loans, asset-backed finance, bridging loans, invoice financing, spot
              factoring, working capital finance, small business loans (including those for sole
              traders), property finance (including commercial mortgages), merchant credit advances,
              or revolving credit facilities — we’ve got you covered.
            </p>
            <p>
              We also offer a wide range of chartered tax and accounting services. Our award-winning
              expertise includes R&D Tax Credits (including HMRC enquiries), Capital Allowances,
              Land Remediation Relief, EIS/SEIS share schemes, and Chartered Tax services.
            </p>
          </MKTypography>
        </div>
      </Card>

      <MKBox pt={6} px={0} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Blogs;
