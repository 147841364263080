import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cx from "../../../style/custom.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
/* import {  Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5"; */
import { IoLockClosedOutline } from "react-icons/io5";
import { showSuccessToast } from "utils/Toster";
const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  const navigate = useNavigate();
  const userType = localStorage.getItem("UserType");
  const onSubmit = async (data) => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }
    const requestBody = {
      email: localStorage.getItem("email"),
      old_password: data.old_password,
      password: data.password,
      confirm_password: data.confirmPassword,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}changepassword/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const result = await response.json();
      if (response.ok) {
        if (userType === "is_business") {
          navigate("/dashboard-business");
          showSuccessToast("Password changed successfully!");
        } else {
          navigate("/dashboard");
          showSuccessToast("Password changed successfully!");
        }
      } else {
        setErrorMessage(result.msg || "Failed to change password.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className={Cx.signupage}>
              <div className={Cx.signtop}>
                {/* <Link
                  to={userType === "is_business" ? "/dashboard-business" : "/dashboard"}
                  className={Cx.backbtn}
                >
                  <IoArrowBackOutline />
                </Link> */}
                <div className={Cx.introText}>
                  <h2>Change password</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={Cx.signupForm}>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <IoLockClosedOutline />
                    </div>

                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Current Password"
                      {...register("old_password", { required: "Old password is required" })}
                    />
                    <FontAwesomeIcon
                      icon={showCurrentPassword ? faEyeSlash : faEye}
                      className={Cx.toggleIcon}
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    />
                  </div>
                  {errors.old_password && (
                    <p style={{ color: "red" }} className={Cx.errorMessage}>
                      {errors.old_password.message}
                    </p>
                  )}
                </div>

                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <IoLockClosedOutline />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      {...register("password", { required: "New password is required" })}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className={Cx.toggleIcon}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {errors.password && (
                    <p style={{ color: "red" }} className={Cx.errorMessage}>
                      {errors.password.message}
                    </p>
                  )}
                </div>

                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <IoLockClosedOutline />
                    </div>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      {...register("confirmPassword", { required: "Please confirm your password" })}
                    />
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                      className={Cx.toggleIcon}
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <p className={Cx.errorMessage} style={{ color: "red" }}>
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>

                {errorMessage && (
                  <p className={Cx.errorMessage} style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
                <div className={Cx.mainheading}>
                  <button type="submit" className={Cx.submitButton2}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
