import React, { useState, useEffect } from "react";
//import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
//import { NavDropdown } from "react-bootstrap";
import Cx from "./page.module.scss";
import Logo from "../../assets/images/agfinancelogs/logoheader.svg";
import routes from "../../routes";
import { Link } from "react-router-dom";

const Headerhome = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50); // Adjust 50 to trigger at your preferred scroll point
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const renderNavLinks = () => {
    return routes.map((route) => {
      if (!route.collapse) {
        return (
          <Link key={route.name} to={route.route} className={Cx.navlink}>
            {route.name}
          </Link>
        );
      }
      return null;
    });
  };

  const renderDropdown = () => {
    const aboutRoute = routes.find((route) => route.name === "About");

    if (aboutRoute && aboutRoute.collapse) {
      return (
        <>
          <div
            className={Cx.customNavDropdown}
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
            <Link to={aboutRoute.route} className={Cx.navlink}>
              {aboutRoute.name}
            </Link>
            {dropdownVisible && (
              <div className={Cx.navpoints2}>
                {aboutRoute.collapse.map((subRoute) => (
                  <Link key={subRoute.name} to={subRoute.route} className={Cx.navlink}>
                    {subRoute.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`${Cx.mainheader} ${isSticky ? Cx.headerscroll : ""}`}
      >
        <div className="container-fluid">
          <Navbar.Brand href="/" className={Cx.logoimge}>
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <div className={Cx.navbarcollapse}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className={Cx.navpoints}>
                {renderNavLinks()}
                {renderDropdown()}
              </div>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Headerhome;
