import React, { useEffect } from "react";
import Cx from "../../../style/custom.module.scss";
//import { useNavigate } from "react-router-dom";
//import { IoArrowBackOutline } from "react-icons/io5";
import { LiaIndustrySolid } from "react-icons/lia";
import { useForm } from "react-hook-form";
import axios from "axios";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoMailOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
const CreateProfile = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const id = localStorage.getItem("userId");
  //const navigate = useNavigate();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${id}`);
        const fetchedData = response?.data;
        if (fetchedData) {
          setValue("email", fetchedData.email || "");
          setValue("full_name", fetchedData.full_name || "");
          setValue("mobile_no", fetchedData.mobile_no || "");
          setValue("company_name", fetchedData.company_name || "");
          setValue("industry", fetchedData.industry || "");
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    if (id) {
      fetchDocuments();
    }
  }, [id, setValue]);

  const onSubmit = async (formData) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}user/${id}/`, {
        email: formData.email,
        full_name: formData.full_name,
        mobile_no: formData.mobile_no,
        company_name: formData.company_name,
        industry: formData.industry,
      });

      console.log(response.data, "Updated Data");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className={Cx.signupage}>
              <div className={Cx.signtop}>
                {/* <span className={Cx.backbtn} onClick={() => navigate(-1)}>
                  <IoArrowBackOutline />
                </span> */}
                <div className={Cx.introText}>
                  <h2>My Profile</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={Cx.signupForm}>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <IoMailOutline />
                    </div>

                    <input type="email" {...register("email")} placeholder="Email" readOnly />
                  </div>
                </div>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <MdOutlineLocalPhone />
                    </div>

                    <input
                      type="tel"
                      {...register("mobile_no")}
                      placeholder="Mobile Number"
                      readOnly
                    />
                  </div>
                </div>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <HiOutlineUser />
                    </div>

                    <input
                      type="text"
                      {...register("full_name", { required: "Name is required" })}
                      placeholder="Type your Name here"
                    />
                  </div>
                  {errors.full_name && (
                    <p className={Cx.errorMessage}>{errors.full_name.message}</p>
                  )}
                </div>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <HiOutlineBuildingOffice2 />
                    </div>

                    <input
                      type="text"
                      {...register("company_name", { required: "Company name is required" })}
                      placeholder="Company Name"
                    />
                  </div>
                  {errors.company_name && (
                    <p className={Cx.errorMessage}>{errors.company_name.message}</p>
                  )}
                </div>

                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <LiaIndustrySolid />
                    </div>

                    <input
                      type="text"
                      {...register("industry", { required: "Industry Type is required" })}
                      placeholder="Industry Type"
                    />
                  </div>
                  {errors.industry && <p className={Cx.errorMessage}>{errors.industry.message}</p>}
                </div>

                <div className={Cx.mainheading}>
                  <button type="submit" className={Cx.submitButton2}>
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateProfile;
