import React, { useState } from "react";
import Cx from "./page.module.scss"; // Ensure this path is correct for your SCSS module
import OtpInput from "react18-input-otp";
import { useNavigate } from "react-router-dom";

import { otpVerification } from "../../../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import { showSuccessToast, showErrorToast } from "../../../utils/Toster";

const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");

    if (!email) {
      showErrorToast("Email not found in local storage");
      return;
    }

    try {
      const data = {
        email: email,
        otp: otp,
      };

      const actionResult = await dispatch(otpVerification(data));
      if (otpVerification.fulfilled.match(actionResult)) {
        showSuccessToast(actionResult?.payload?.msg);
        navigate("/reset-password");
      } else {
        showErrorToast(actionResult.payload);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showErrorToast("An unexpected error occurred");
    }
  };

  // Handle OTP change
  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  return (
    <section className={Cx.otpVerificationSection}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className={Cx.signtop}>
              {/*  <Link to="/forgetpassword" className={Cx.backbtn}>
                <IoArrowBackOutline />
              </Link> */}
              <div className={Cx.introText}>
                <h2>Enter OTP</h2>
              </div>
            </div>
            <div className={Cx.accounts}>
              <p>Please enter the OTP sent to your email/phone.</p>
            </div>

            <form className={Cx.otpverificationForm} onSubmit={onSubmit}>
              <div className={Cx.formGroup}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    inputStyle={Cx.otpInput}
                    isInputNum={true}
                  />
                </div>
              </div>
              <div className={Cx.mainheading}>
                <button type="submit" className={Cx.submitButton}>
                  Verify
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpVerification;
