import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state
const initialState = {
  user: null,
  loading: false,
  error: null,
};

// Define the async thunk for login
const loginUser = createAsyncThunk("auth/loginUser", async (loginData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}login/`, loginData);
    return response.data;
  } catch (error) {
    console.log(error.response?.data?.msg, "error>>>>>>>>>>>");
    return error.response?.data?.msg;
  }
});

// Define the async thunk for loginGoogle

const loginUserGoogle = createAsyncThunk("auth/loginGoogle", async (loginData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}loginsso/`, loginData);
    return response.data;
  } catch (error) {
    console.log(error.response?.data?.msg, "error>>>>>>>>>>>");
    return error.response?.data?.msg;
  }
});

// Define the async thunk for registration
const registerUser = createAsyncThunk("auth/registerUser", async (registerData, thunkAPI) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}user/`, registerData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || "Registration failed");
  }
});

const registerUserGoogleAndApple = createAsyncThunk(
  "auth/registerUserGoogleAndApple",
  async (registerData, thunkAPI) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}register/`, registerData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Registration failed");
    }
  }
);

// Define the async thunk for updating user status
const registerUserStatus = createAsyncThunk(
  "auth/registerUserStatus",
  async (userStatusUpdate, thunkAPI) => {
    if (userStatusUpdate?.is_taxrelief) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}user/${userStatusUpdate?.userId}/`,
          {
            is_taxrelief: userStatusUpdate?.is_taxrelief,
          }
        );
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || "User status update failed"
        );
      }
    } else {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}user/${userStatusUpdate?.userId}/`,
          {
            is_business: userStatusUpdate?.is_business,
          }
        );
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || "User status update failed"
        );
      }
    }
  }
);

const otpVerification = createAsyncThunk("auth/otpVerification", async (resetData, thunkAPI) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}otp-verify/`, resetData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.error);
  }
});

// Define the async thunk for password reset
const resetPassword = createAsyncThunk("auth/resetPassword", async (resetData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}password-reset-request/`,
      resetData
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.error || "Password reset failed");
  }
});

// Create the slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle login actions
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(loginUserGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUserGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUserGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle registration actions
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(registerUserGoogleAndApple.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUserGoogleAndApple.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUserGoogleAndApple.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle user status update actions
      .addCase(registerUserStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUserStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUserStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(otpVerification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(otpVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle password reset actions
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        // Handle success (e.g., show a success message)
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export {
  loginUser,
  registerUser,
  resetPassword,
  registerUserStatus,
  otpVerification,
  registerUserGoogleAndApple,
  loginUserGoogle,
};
export default authSlice.reducer;
