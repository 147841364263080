import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching data from Calendly API
const fetchCalendlyData = createAsyncThunk("calendly/fetchData", async () => {
  try {
    const response = await axios.get("https://api.agfinancehub.com/api/calendly/");
    return response?.data;
  } catch (error) {
    throw error?.response ? error?.response?.data : error?.message;
  }
});

const calendlySlice = createSlice({
  name: "calendly",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendlyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCalendlyData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCalendlyData.rejected, (state, action) => {
        console.error("Error:", action.error);
        state.loading = false;
        state.error = action.error?.message || "Something went wrong";
      });
  },
});

export { fetchCalendlyData };

export default calendlySlice.reducer;
