import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineFileUpload } from "react-icons/md";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { FaRegEye } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import axios from "axios";
import { showSuccessToast } from "utils/Toster";
import Headerhome from "layouts/hearderhome/Headerhome";

const UploadBusinessDocument = () => {
  const [userId] = useState(localStorage.getItem("userId"));
  const [selectedFiles, setSelectedFiles] = useState({
    Vatq1: null,
    Vatq2: null,
    Vatq3: null,
    Vatq4: null,
    Busi_accounts: null,
    businessbankstatement: null,
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkData, setLinkData] = useState();

  const userType = localStorage.getItem("UserType");

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [fileType]: file,
      }));
    }
  };
  const handleViewPdf = (fileUrl) => {
    // Check if the fileUrl is a File object
    if (fileUrl instanceof File) {
      const generatedUrl = URL.createObjectURL(fileUrl);
      window.open(generatedUrl, "_blank");
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleRemoveFile = (fileType) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: null,
    }));
  };

  const onQuationHandler = async () => {
    try {
      if (!userId) {
        console.log("User ID not found");
        return;
      }

      setLoading(true);
      const formData = new FormData();
      Object.keys(selectedFiles).forEach((key) => {
        if (selectedFiles[key]) {
          formData.append(key, selectedFiles[key]);
        }
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}user/${userId}/`,
        formData,
        config
      );
      if (response) {
        if (open) {
          navigate("/dashboard-business");
        }
        showSuccessToast("Files uploaded successfully");
      } else {
        console.error("Error uploading files");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setLoading(false);
    }
  };

  const onCalendarHandler = async () => {
    setLinkData();
    const apiMeetingData = await axios.get(`${process.env.REACT_APP_BASE_URL}calendly/`);
    const meetingData = apiMeetingData?.data;
    const discoveryCallLink = meetingData?.find((item) => item?.type === "discovery");
    const linkData = discoveryCallLink?.link;
    setLinkData(linkData);
  };

  useEffect(() => {
    onCalendarHandler();
  }, []);

  const renderFileUpload = (label, fileType) => {
    return (
      <div className={Cx.fileUploadSection}>
        <div className={Cx.fileUploadHeader}>
          <h2>{label}</h2>
          <div className={Cx.fileActions}>
            {selectedFiles[fileType] ? (
              <>
                <div className={Cx.iconsponts}>
                  <span
                    onClick={() => handleViewPdf(selectedFiles[fileType])}
                    className={Cx.iconcircel}
                  >
                    <FaRegEye />
                  </span>
                  {/* <span className={Cx.fileName}>{selectedFiles[fileType].name}</span> */}
                  <span className={Cx.iconcircel} onClick={() => handleRemoveFile(fileType)}>
                    <HiOutlineTrash />
                  </span>
                </div>
              </>
            ) : (
              <button
                type="button"
                className={Cx.uloadbtn}
                onClick={() => document.getElementById(`${fileType}Input`).click()}
              >
                <MdOutlineFileUpload />
              </button>
            )}
            <input
              id={`${fileType}Input`}
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e, fileType)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Headerhome />
      <section>
        <div className="container">
          <div className="row mt-10">
            <div className="col-lg-6 m-auto">
              <div className={Cx.whatarelooking}>
                <div className={Cx.mainheadinglookingfor}>
                  <h1>Upload Documents</h1>
                </div>
                <div className={Cx.uploaddocumentform}>
                  {renderFileUpload("Q.1 VAT Return", "Vatq1")}
                  {renderFileUpload("Q.2 VAT Return", "Vatq2")}
                  {renderFileUpload("Q.3 VAT Return", "Vatq3")}
                  {renderFileUpload("Q.4 VAT Return", "Vatq4")}
                  {renderFileUpload("Most recent filed accounts", "Busi_accounts")}
                  {renderFileUpload(
                    "Last 6 months of business bank statements",
                    "businessbankstatement"
                  )}

                  <div className={Cx.mainheading}>
                    <button
                      onClick={() => {
                        setOpen(true);
                        onQuationHandler();
                      }}
                      className={Cx.submitButton}
                    >
                      {loading ? "Uploading..." : "Upload"}
                    </button>
                    {open && (
                      <div className={Cx.mainheading}>
                        <Modal
                          open={open}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className={Cx.popuarea}>
                            <Typography>
                              {userType == "is_business" ? (
                                <Link className={Cx.linkbtn2} to="/dashboard-business">
                                  We&apos;ve received your discovery questionnaire, and our team
                                  will connect with you soon.
                                </Link>
                              ) : (
                                <Link className={Cx.linkbtn2} to="/dashboard">
                                  We&apos;ve received your discovery questionnaire, and our team
                                  will connect with you soon.
                                </Link>
                              )}
                              <br />
                              Or
                              <p>
                                <Link className={Cx.linkbtn2} to={linkData} target="_blank">
                                  Alternatively, you can book your discovery call by clicking here!
                                </Link>
                              </p>
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    )}

                    <div className={Cx.mainheading}>
                      <h5>Don&apos;t have these to hand?</h5>
                      <Link to={linkData} className={Cx.linkbtn} target="_blank">
                        That&apos;s okay, just click &apos;Complete&apos; to book your discovery
                        call.
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={6} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default UploadBusinessDocument;
