import React from "react";
import { useForm } from "react-hook-form";
import Cx from "../../../style/custom.module.scss";
import { useNavigate } from "react-router-dom";
import { IoMailOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";
import { resetPassword } from "../../../redux/slice/authSlice";
import { Link } from "react-router-dom";
const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const actionResult = await dispatch(resetPassword(data));
      if (resetPassword.fulfilled.match(actionResult)) {
        navigate("/otp-verification");
        console.log("actionResult?.payload", actionResult?.payload);
        localStorage.setItem("email", data?.email);
        showSuccessToast("Otp Successfully");
      } else {
        showErrorToast("Otp failed:", actionResult.payload);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className={Cx.signupage}>
              <div className={Cx.signtop}>
                {/*  <Link to="/login" className={Cx.backbtn}>
                  <IoArrowBackOutline />
                </Link> */}
                <div className={Cx.introText}>
                  <h2>Forgot Password</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={Cx.signupForm}>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <div className={Cx.icon}>
                      <IoMailOutline />
                    </div>
                    <input
                      type="email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                      placeholder="Email"
                    />
                  </div>
                  {errors.email && <p className={Cx.errorMessage}>{errors.email.message}</p>}
                </div>

                <div className={Cx.mainheading}>
                  <button type="submit" className={Cx.submitButton2}>
                    Submit
                  </button>
                </div>
                <div className={Cx.mainheading}>
                  <p>
                    We will share a reset password link to the email if it is registered with us!
                  </p>
                </div>
                <div className={Cx.mainheading}>
                  <p>
                    Don&apos;t have an account?
                    <br />
                    <span>
                      <Link to="/login" className={Cx.linkbtn}>
                        Sign Up!
                      </Link>
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
