import React, { useEffect } from "react";
import Cx from "./page.module.scss";

const NotFound = () => {
  useEffect(() => {
    const PAGE_NOT_FOUND = document.querySelector(`.${Cx.caution__tape} .${Cx.PAGE_NOT_FOUND}`);
    const tape__center = document.getElementById(Cx.caution__tape__center);
    const tape__left = document.getElementById(Cx.caution__tape__left);
    const ERROR = document.querySelector(`.${Cx.caution__tape} .${Cx.ERROR}`);
    const bodyWidth = document.getElementById(Cx.body)?.clientWidth ?? 0;

    const warning_len = bodyWidth / (PAGE_NOT_FOUND?.clientWidth ?? 1) / 1.8 - 1;
    const caution_len = bodyWidth / (ERROR?.clientWidth ?? 1) / 1.8 - 1;

    if (tape__center && PAGE_NOT_FOUND) {
      for (let i = 0; i < warning_len; i++) {
        tape__center.innerHTML += PAGE_NOT_FOUND.outerHTML;
      }
    }

    if (tape__left && ERROR) {
      for (let i = 0; i < caution_len; i++) {
        tape__left.innerHTML += ERROR.outerHTML;
      }
    }

    const handleDeviceOrientation = (e) => {
      if (e.gamma !== null && e.beta !== null && tape__left && tape__center) {
        tape__left.style.transform = `translate(${e.gamma / 3}px, ${e.beta / 3}px) rotateZ(-45deg)`;
        tape__center.style.transform = `translate(${e.gamma / 2}px, ${
          e.beta / 2
        }px) scale(1.5) rotateZ(5deg)`;
      }
    };

    const handleMouseMove = (e) => {
      if (tape__left && tape__center) {
        tape__left.style.transform = `translate(${e.pageX / 30}px, ${
          e.pageY / 30
        }px) rotateZ(-45deg)`;
        tape__center.style.transform = `translate(${e.pageX / 10}px, ${
          e.pageY / 10
        }px) scale(1.5) rotateZ(5deg)`;
      }
    };

    window.addEventListener("deviceorientation", handleDeviceOrientation);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <section id={Cx.body} className={Cx.mainContainer}>
        <div className={Cx.background}>
          {/*    <div id={Cx.caution__tape__left} className={`${Cx.caution__tape} ${Cx.left}`}>
            <p className={Cx.ERROR}>ERROR</p>
          </div> */}
          <div className={Cx.main}>
            <h1>4</h1>
            <h1 className={Cx.X}>
              <span className={`${Cx.caution__tape} ${Cx.text}`}></span>
              <span className={`${Cx.caution__tape} ${Cx.text}`}></span>
            </h1>
            <h1>4</h1>
          </div>
          <div id={Cx.caution__tape__center} className={`${Cx.caution__tape} ${Cx.center}`}>
            <p className={Cx.page_not_found}>PAGE NOT FOUND!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
